import {Link } from 'gatsby';
import React, {useEffect} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

import {connect} from 'react-redux';

import {getPropertiesAction, getTotalListFilters, REDUX_UPDATE_FILTERS} from '../redux/propertiesDucks';
import {getDevelopmentsAction} from '../redux/developmentsDucks';

import {existOperation,existDevelopment, makeItemsNav} from '../helpers/helper.rendering'
import {getOperationView} from '../helpers/helper.filters'



import { getNoveltiesAction } from '../redux/noveltiesDucks';
import { element } from 'prop-types';

const MenuNav = ({filters,curFilters,api_key,developments,novelties,dispatch,setOpen}) => {
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
            design  {
                icons
            }
              global_email
              social{
                facebook
                instagram
                linkedin
                youtube
                twitter
              }
              branch_office{
                name
                address
                city
                region
                broker_id
                contact {
                  phone
                  mail
                  whatsapp
                }
              }
              env { 
                APISERVER
                TENANTID
              }
              sections {
                  footer {
                      title_contact
                      title_social
                      title_newsletter
                      title_office
                      button_contact{
                          link
                          value
                      }
                  }
              }
          }
    }`)

    const { design: { icons },env } = realEstate
    const iconsStyle = icons === 'Solid' || icons === 'Light' ? `-${icons.toLowerCase()}` : ''

    const location = useLocation()
    const pathname = location.pathname.split("/")[1]

    useEffect(() => {
        if(api_key){
            dispatch(getDevelopmentsAction())
            dispatch(getTotalListFilters())
        }
    }, [api_key])
    
    useEffect(() => {
        dispatch(getNoveltiesAction(env))
    },[])

    const staticBody = () => {
        var body = document.body;
        body.classList.remove("overflow-hidden");
    }

    const checkActive = (_pathname) => {
        if(_pathname === 'inicio' && pathname === ''){return true}
        if((curFilters.operation === "alquiler-temporario" && _pathname === 'alquiler temp.')){
            return true
        }
        if((_pathname === 'venta' || _pathname === "alquiler" && _pathname !== 'alquiler temp.') && pathname.includes('venta') || pathname.includes('alquiler')){
            if(curFilters.operation === 'alquiler' && _pathname === 'alquiler'){
                return true;
            }
            if(curFilters.operation === 'venta' && _pathname === 'venta'){
                return true;
            }
        }
        else{
            if(pathname === (_pathname)){
                return true;
            }
        }
        return false
    }

    const fillFilters = (item) => {
        return {
            location: [],
            type: [],
            operation: item,
            environments: [],
            price:{
              type:'',
              priceMin:'',
              priceMax:''
            },
            surface:{
              type:'',
              surfaceMin:"",
              surfaceMax:"",
            },
            environments_types: [],
            generals: [],
            services: [],
            specials: [],
          }
    }

    return realEstate.sections ? (
        <nav className="nav-content opened d-flex align-items-center">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-8 pe-xl-5" style={{alignSelf:"center"}}>
                        <ul className="nav-menu d-flex flex-column d-lg-block">
                            {makeItemsNav(developments,filters,novelties).map((item,index) => (
                                <li className={(checkActive(item.name.toLowerCase()) ? 'active' : '')}>
                                    {
                                        (!checkActive(item.name.toLowerCase()) 
                                            ?   <Link onClick={() => staticBody() + ((item.name.toLowerCase() === 'venta' || item.name.toLowerCase() === 'alquiler') ? dispatch(REDUX_UPDATE_FILTERS(fillFilters(item.name),item.name)) + setOpen(false) + dispatch(getPropertiesAction()) : dispatch(REDUX_UPDATE_FILTERS(fillFilters("venta"),"venta")))} className={(checkActive(item.name.toLowerCase()) ? 'active' : '')} to={item.link}>{item.name} <span>0{index+1}</span>
                                                </Link>
                                            :   <div onClick={() => staticBody() + setOpen(false)} 
                                                        >{item.name} <span>0{index+1}</span>
                                                </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-lg-4 px-xl-5 contact-nav d-none d-lg-flex align-items-center">
                        <div className="row">
                            <div class="item-contact col-8 col-lg-12">
                                <h5>{realEstate.sections.footer.title_contact}</h5>
                                <a class="hover-service-link " target="_blank" href={"mailto:" + realEstate.global_email}>{realEstate.global_email}</a>
                            </div>
                            <div className="col-12 max-heigth-scroll">
                                {realEstate.branch_office.map((item,index) => (
                                    <div class="item-contact mb-lg-0 mt-lg-4 d-none d-lg-block col-12">
                                        <h5>{realEstate.sections.footer.title_office + " "}{item.name}</h5>
                                        <p >{item.address}</p>
                                        <p >{item.region}</p>
                                        <a target="_blank" className={"hover-link " + (!item.contact.mail && 'd-none')} href={"mailto:"+item.contact.mail}>{item.contact.mail}</a>
                                        {item.contact.phone.map(phone => (
                                            <p >Teléfono {phone}</p>
                                        ))}
                                        <p className={item.contact.whatsapp === '' && 'd-none'}>Whatsapp +{ item.contact.whatsapp }</p>
                                        <p className={item.broker_id === '' && 'd-none'}>Matrícula {item.broker_id}</p>
                                    </div>
                                ))} 
                            </div>
                            <div class="item-contact social col-4 col-lg-4">
                                <h5>{realEstate.sections.footer.title_social}</h5> 
                                <a target="_blank" href={realEstate.social.instagram} class={realEstate.social.instagram ? "d-inline" : 'd-none'}><i class={`icon-instagram${iconsStyle}`}></i></a>
                                <a target="_blank" href={realEstate.social.facebook} class={realEstate.social.facebook ? "d-inline" : 'd-none'}><i class={`icon-facebook${iconsStyle}`}></i></a>
                                <a target="_blank" href={realEstate.social.linkedin} class={realEstate.social.linkedin ? "d-inline" : 'd-none'}><i class={`icon-linkedin${iconsStyle}`}></i></a>
                                <a target="_blank" href={realEstate.social.youtube} class={realEstate.social.youtube ? "d-inline" : 'd-none'}><i class={`icon-youtube${iconsStyle}`}></i></a>
                                <a target="_blank" href={realEstate.social.twitter} class={realEstate.social.twitter ? "d-inline" : 'd-none'}><i class={`icon-twitter${iconsStyle}`}></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    ):''
}

export default connect(state => ({
    filters:state.properties.totalListFilters,
    curFilters:state.properties.filters,
    novelties:state.novelties.grid_novelties,
    api_key:state.settings.keys.tokko,
    developments:state.developments.developments
}),null)(MenuNav);
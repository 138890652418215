import { Link } from "gatsby"
import React, {useState} from "react"
import $ from 'jquery'

// import LOGO_NORMAL from "../images/svg/laura-farias-logo.svg";
// import LOGO_WHITE from "../images/svg/laura-farias-logo-white.svg";

import NavMenu from "./menu-nav";
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from "@reach/router";
import { useEffect } from "react";
import {cleanDevelopmentAction} from "../redux/developmentsDucks";
import {clearPropertiesAction, clearPropertyAction} from "../redux/propertiesDucks";

import { connect } from "react-redux";
import { getfavorites } from "../helpers/helper.favorites";
import { graphql, useStaticQuery } from 'gatsby';

function Main({settings,dispatch,updaterFavorites, siteTitle, logo}) {

    const { realEstate: {design: {icons}} } = useStaticQuery(graphql`
            query {
                realEstate {
                    design {
                        icons
                    }
                }
    }`)
    const iconsStyle = icons === 'Solid' || icons === 'Light' ? `-${icons.toLowerCase()}` : ''

    const [open,setOpen] = useState(false);
    const location = useLocation()

    const [countFavorites,setCountFavorites] = useState(getfavorites("prop",settings.short_name).length)

    useEffect(() => {
        if(!location.pathname.includes('/emprendimientos')){
            dispatch(cleanDevelopmentAction())
        }
        if(!location.pathname.includes('/propiedad')){
            dispatch(clearPropertyAction())
        }
        dispatch(clearPropertiesAction())
        setCountFavorites(getfavorites("prop",settings.short_name).length)
    },[location])

    const staticBody = () => {
        var body = document.body;
        body.classList.toggle("overflow-hidden");
    }

    useEffect(() => {
        setCountFavorites(getfavorites("prop",settings.short_name).length)
        $("#count_favorites").removeClass("animate__heartBeat");
        $("#count_favorites_mobile").removeClass("animate__heartBeat");
        setTimeout(function(){
            $("#count_favorites").addClass("animate__heartBeat");
            $("#count_favorites_mobile").addClass("animate__heartBeat");
        }, 100);
        // $("#count_favorites").toggleClass("animate__heartBeat");
    },[updaterFavorites])

    return(
    <header id="header" className={"blur " + (open ? 'opened' : '')}>
        <Toaster
        toastOptions={{
            className: 'toast-className',
            style: {
                maxWidth: 'fit-content',
                
              },
        }} />
            <nav className="brand">
                <div className="container-fluid">
                    <div className="row justify-content-center align-items-center">
                        {/* <div className={"col-3 icon-favorites align-items-center justify-content-lg-center " + (countFavorites.toString() === '0' ? "d-lg-none d-flex" : "d-none")} onClick={() => toast.error("No tienes Propiedades favoritas seleccionadas.")} >
                            <i className={`icon-favoritos-heart${iconsStyle} ` + (countFavorites.toString() === '0' && ' disabled ') + (location.pathname.includes("favoritos") ? 'active' : '')}></i>
                        </div> */}
                        <div className={"col-3 d-flex d-lg-none align-items-center justify-content-lg-center "}>
                            <Link className="d-flex icon-favorites" to={"/favoritos"}><i className={`icon-favoritos-heart${iconsStyle} ` + (location.pathname.includes("favoritos") ? 'active' : '')}></i>
                                <span id="count_favorites-mobile" className={'ms-2 d-block d-lg-none ' + " animate__animated " }>{countFavorites}</span> 
                            </Link>
                            {/* <Link to="/favoritos?comparar" className="compare-link ms-4">
                                COMPARAR
                            </Link> */}
                        </div>
                        <div className="col-lg-9 col-6 justify-content-start">
                            <Link to="/" className="logo">
                                <img className="logo-header" src={logo} />
                            </Link>
                        </div>
                        <div className="col-3 text-right d-flex align-items-center justify-content-end">
                            {/* <Link to="/favoritos?comparar" className="compare-link me-4 d-none d-lg-block">
                                COMPARAR
                            </Link> */}
                            {
                                (
                                countFavorites === 0
                                ?   <div className="hover-like d-lg-flex d-none align-items-center" onClick={() => toast.error("No tienes Propiedades favoritas seleccionadas.")} >
                                        <i className={`me-4 icon-favoritos-heart${iconsStyle} `  + (countFavorites < 1 && ' disabled ') + (location.pathname.includes("favoritos") ? ' active ' : '')}></i>
                                    </div> 
                                :   <div className={"hover-like d-lg-flex d-none align-items-center "}>
                                        <span id="count_favorites" className={'' + (!countFavorites ? ' d-none ' : ' d-none d-lg-block ') + " animate__animated " }>{countFavorites}</span> 
                                        <Link className="me-4 icon-favorites d-none d-lg-flex align-items-center justify-content-center" to={"/favoritos"}><i className={`icon-favoritos-heart${iconsStyle} `  + (countFavorites < 1 && ' disabled ') + (location.pathname.includes("favoritos") ? ' active ' : '')}></i></Link>
                                    </div>   
                                )
                            }
                            
                            <button id="toggle-nav" onClick={() => setOpen(!open) + staticBody()}>
                                <div className="d-flex align-items-center">
                                    <span className="status_nav d-none d-lg-inline">MENÚ </span>
                                    <span className="ms-4"><i></i><i></i><i></i></span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
        <NavMenu setOpen={setOpen} />
  </header>
)}

export default connect(state => ({
    settings: state.settings,
    updaterFavorites:state.properties.updaterFavorites
}),null)(Main);